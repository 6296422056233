var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"trip-card grid-sm-4 grid-md-4 grid-lg-4",attrs:{"to":`${_vm.$locale.base}${_vm.link}`}},[_c('div',{staticClass:"trip-card__wrapper"},[_c('div',{staticClass:"trip-card__image-holder"},[_c('img',{staticClass:"trip-card__image",attrs:{"src":_vm.image,"alt":""}}),_c('Favorite',{staticClass:"trip-card__favorite",attrs:{"params":{id: _vm.id, entity: _vm.entity, favorited: _vm.favorited}}}),_c('Row',{staticClass:"trip-card__badges -m-h-5",attrs:{"wrap":""}},_vm._l((_vm.params.tags),function(badge){return _c('Badge',{key:`badge-trip-card-${badge.id}`,attrs:{"params":{text: badge.title, colors: {
                     text: '#ffffff', background: badge.color}}}})}),1)],1),_c('div',{staticClass:"trip-card__meta"},[_c('div',{staticClass:"trip-card__type-holder"},[_c('span',{staticClass:"trip-card__type"},[_vm._v(_vm._s(_vm.$type(_vm.params.type)))])]),_c('div',{staticClass:"trip-card__title-holder"},[_c('h5',{staticClass:"trip-card__title"},[_vm._v(_vm._s(_vm.params.title))])]),_c('Row',{staticClass:"trip-card__info -m-h-15"},[(_vm.params.duration)?_c('Column',{staticClass:"trip-card__info-column"},[_c('Icon',{staticClass:"trip-card__info-icon",attrs:{"xlink":"duration","viewport":"0 0 20 20"}}),_c('span',{staticClass:"trip-card__info-value"},[_vm._v(_vm._s(_vm.params.duration))])],1):_vm._e(),(_vm.params.distance)?_c('Column',{staticClass:"trip-card__info-column"},[_c('Icon',{staticClass:"trip-card__info-icon",attrs:{"xlink":"route","viewport":"0 0 20 20"}}),_c('span',{staticClass:"trip-card__info-value"},[_vm._v(_vm._s(_vm.params.distance))])],1):_vm._e(),_c('Column',{staticClass:"trip-card__info-column"},[_c('Rating',{attrs:{"params":{id: _vm.id,
                            component: 'Column',
                            align: 'start',
                            type: 'trips',
                            rating: _vm.rating,
                            currentUserRating: _vm.currentUserRating}}})],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }