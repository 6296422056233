<template>
  <router-link class="trip-card grid-sm-4 grid-md-4 grid-lg-4"
               :to="`${$locale.base}${link}`">
    <div class="trip-card__wrapper">
      <div class="trip-card__image-holder">
        <img class="trip-card__image"
             :src="image"
             alt/>
        <Favorite class="trip-card__favorite"
                  :params="{id, entity, favorited}"/>
        <Row class="trip-card__badges -m-h-5"
             wrap>
          <Badge v-for="badge in params.tags"
                 :key="`badge-trip-card-${badge.id}`"
                 :params="{text: badge.title, colors: {
                       text: '#ffffff', background: badge.color}}"/>
        </Row>
      </div>
      <div class="trip-card__meta">
        <div class="trip-card__type-holder">
          <span class="trip-card__type">{{$type(params.type)}}</span>
        </div>
        <div class="trip-card__title-holder">
          <h5 class="trip-card__title">{{params.title}}</h5>
        </div>
        <Row class="trip-card__info -m-h-15">
          <Column class="trip-card__info-column"
                  v-if="params.duration">
            <Icon class="trip-card__info-icon"
                  xlink="duration"
                  viewport="0 0 20 20"/>
            <span class="trip-card__info-value">{{params.duration}}</span>
          </Column>
          <Column class="trip-card__info-column"
                  v-if="params.distance">
            <Icon class="trip-card__info-icon"
                  xlink="route"
                  viewport="0 0 20 20"/>
            <span class="trip-card__info-value">{{params.distance}}</span>
          </Column>
          <Column class="trip-card__info-column">
            <Rating :params="{id,
                              component: 'Column',
                              align: 'start',
                              type: 'trips',
                              rating,
                              currentUserRating}"/>
          </Column>
        </Row>
      </div>
    </div>
  </router-link>
</template>

<script>
import errorImage from '@/assets/images/svg/error.svg';

export default {
  name: 'TripCard',
  props: {
    params: {},
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    link() {
      return `/${this?.params?.entity}/${this?.params?.id}`;
    },
    favorited() {
      return this?.params?.favorited;
    },
    rating() {
      return this?.params?.rating;
    },
    currentUserRating() {
      return this?.params?.currentUserRating;
    },
    image() {
      if (this?.params?.images?.length > 0) {
        return this?.params?.images[0];
      }
      return errorImage;
    },
  },
};
</script>
